import allConf from "./config";
const conf = allConf[process.env.REACT_APP_CONFIG_FILE_NAME] ? allConf[process.env.REACT_APP_CONFIG_FILE_NAME] : allConf.demo;

export const API_URL = {
    // LEAD_API: "http://localhost:4020/",
    // INVENTORY_API: "http://localhost:4021/",
    // DEALER_API: "http://localhost:4022/",
    GETWAY_API: process.env.REACT_APP_GETWAY_API,
    // GETWAY_API: "https://int-gcloud.gaadi.com/"
    // GETWAY_API: "http://192.168.72.36:8626/"
    // GETWAY_API: "http://localhost:8626/"
    TENANT: process.env.REACT_APP_CONFIG_FILE_NAME,
    USED_CAR_IMAGE_URLS: ['https://otousedcar.storage.googleapis.com/', 'https://storage.googleapis.com/otousedcar/'],
    CDN_IMAGE_URL: 'https://imgcdnused.carbay.com/'
}

export const CONF_VAL =conf;
export const GOOGLE_LOGIN_URL = "https://sso.oto.com/google?redirect="
export const REDIRECT_URL = window.location.origin

